import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import { rhythm } from "../utils/typography"

class AboutPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="About Derek" />
            <h1>About Derek</h1>
            
            
            
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt

              style={{
                marginLeft: rhythm(1),
                marginBottom: 0,
                minWidth: 300,
                minHeight: 300,
                float: "right"

              }}
              
            />
            <p>When I was 12 years old I decided that I wanted to be a teacher. When I was 18 years old I decided that I wanted to be a high school biology and chemistry teacher. When I was 23 years old I accepted my first full-time teaching job as a high school biology and chemistry teacher. When I was 27 years old I decided that I didn’t want to be a high school biology and chemistry teacher any more.</p>

            <p>
              In 1999, I left all security in that full-time teaching job behind and started my own company. I grew the company to the point where we made great things happen for great clients, and my time at the helm growing the company ended in February 2018, when <a href="http://simplyaccessible.com">Simply Accessible</a> was acquired by <a href="https://levelaccess.com">Level Access</a>, where I worked for four years as the Chief eXperience Officer.
            </p>

            <p>
              Now I work at <a href="https://salesforce.com">Salesforce</a> where I am the Vice President of Accessibility and Inclusive Design. I set the accessibility vision, strategy, and direction for our team in our quest to make our products more accessible and inclusive.
            </p>

            <h2>
              Speaking
            </h2>
            <p>
              I’m a well-known speaker with a reputation for inspiring and exciting the audience with practical demonstrations and techniques for making web sites and applications easier for everyone to use. In addition to technical sessions, I’m honoured to have delivered several keynote addresses at many conferences over the past 2 years and many full-day workshops focused on accessibility. I wrote about speaking things for a while at <a href="http://seizetheroom.com">seizetheroom.com</a>.
            </p>

            <h2>
              Days gone by
            </h2>
            <p>
              I was the lead of the <a href="https://www.webstandards.org/">Web Standards Project</a> — a grassroots coalition fighting for standards which ensure simple, affordable access to web technologies for all. We shut WaSP down a while ago, after we felt like our job was done. Turns out, it may not be done.
            </p>

            <p>
              I used to race triathlons. (That’s the first time I’ve ever written that, but given that I haven’t raced since 2010, I don’t think I can say I am a triathlete!) Here’s where I used to write about triathlons and training: <a href="http://ironfeathers.ca">ironfeathers.ca</a>
            </p>

            <h2>
              About this site
            </h2>
            <p>I’m trying to get a fresh start on writing. I’ve been called feather since I was about 12 or 13 years old. That was a long time ago. I couldn’t think of a new or appropriate domain, so I am using feather.ca. Because that’s me.</p>
          </Layout>
        )
      }

}

export default AboutPage

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`